import { useContext } from "react";
import "./style.css";
import { AdminData } from "../../Contexts/AdminData";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import SensorDoorIcon from "@mui/icons-material/SensorDoor";
import PeopleIcon from "@mui/icons-material/People";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import Groups3Icon from "@mui/icons-material/Groups3";
const gridTemplate = "1.5vw 1fr 1fr 1fr 1fr 6vw";
export default function PageList() {
  const [pages, setPages] = useContext(AdminData);
  const Navigate = useNavigate();
  return (
    <div className="pageList container">
      <h2 className="descTitle">Немного о страницах</h2>
      <p className="descText">
        Здесь вы можете администрировать и создавать страницы для магазина.{" "}
        <br />
        Страницы состоят из блоков/шаблонов и могут иметь мета теги, просмотреть
        которые можно нажав на любую из страниц
      </p>
      <div className="statPages">
        <div>
          <div className="svodCont">
            <p>Всего посещений </p>
            <article>
              648 031 <span>+0.4%</span>
            </article>
          </div>
          <div className="svodIcon">
            <SensorDoorIcon></SensorDoorIcon>
          </div>
        </div>
        <div>
          <div className="svodCont">
            <p>Самая популярная страница</p>
            <article>
              Blizzard<span></span>
            </article>
          </div>
          <div className="svodIcon">
            <PeopleIcon></PeopleIcon>
          </div>
        </div>
        <div>
          <div className="svodCont">
            <p>Сессии на страницах ч.</p>
            <article>
              246<span>+32%</span>
            </article>
          </div>
          <div className="svodIcon">
            <LocalGroceryStoreIcon />
          </div>
        </div>
        <div>
          <div className="svodCont">
            <p>Проскроллено блоков</p>
            <article>
              8933<span>+0.22%</span>
            </article>
          </div>
          <div className="svodIcon">
            <Groups3Icon />
          </div>
        </div>
      </div>
      <div className="searchPageList">
        <h3>Список страниц</h3>
        <input type="text" placeholder="Поиск" />
        <div
          className="newplan"
          onClick={() => {
            Navigate(routes.createPage);
          }}
        >
          + Новая страница
        </div>
      </div>
      <div className="usersTable">
        <div
          className="gridTable gridTitle"
          style={{ gridTemplateColumns: gridTemplate }}
        >
          <div></div>
          <div>Название</div>
          <div>Количество блоков</div>
          <div>Адрес</div>
          <div>Видимость</div>
          <div>Действия</div>
        </div>
        {pages &&
          pages.pages &&
          pages.pages.map((em) => {
            return (
              <div
                style={{ gridTemplateColumns: gridTemplate }}
                className="gridTable gridRow"
                onClick={() => {
                  Navigate(
                    routes.changePage +
                      "?key=" +
                      em.ID +
                      "&name=" +
                      em.Names +
                      "&route=" +
                      em.Routes
                  );
                }}
              >
                <input type="checkbox"></input>
                <p
                  className="userName"
                  style={{ opacity: "0.9", textTransform: "capitalize" }}
                >
                  {em.Names}
                </p>
                <div className="userName">
                  {Math.floor(Math.random() * 10) + 1}
                </div>
                <p className="userName">/{em.Routes}</p>

                <p className="availibity" style={{ width: "fit-content" }}>
                  Видна
                </p>
                <div className="userName iconsProduct">
                  <RemoveRedEyeIcon></RemoveRedEyeIcon>
                  <EditIcon></EditIcon>
                  <DeleteIcon></DeleteIcon>
                </div>
              </div>
            );
          })}
        {/*   <div
          className="addNewPage"
          onClick={() => {
            Navigate(routes.createPage);
          }}
        >
          <PlaylistAddRoundedIcon></PlaylistAddRoundedIcon>
          Создать страницу
        </div> */}
      </div>
    </div>
  );
}
