import { useContext, useEffect, useState } from "react";
import "./style.css";
import { User } from "../../../Contexts/User";
import { saveBlocks } from "../../../Api";
import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";

function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
//Сетка
function GridForm({
  products,
  setCurrentProduct,
  currentProduct,
  itemsSearch,
}) {
  const [current, setCurrent] = useState([]);

  function deleteFromGrid(id) {
    setCurrent((l) =>
      l.filter((item) => {
        return item !== id;
      })
    );
  }

  useEffect(() => {
    setCurrentProduct({
      ...currentProduct,
      BlockValue: JSON.stringify(current),
    });
  }, [current]);
  return (
    <div className="gridForm">
      {products.length > 0 &&
        products
          .filter((elem) =>
            elem.Title.toLowerCase().includes(itemsSearch.toLowerCase())
          )
          .map((em, index) => {
            return (
              <div
                className="productAdd"
                onClick={() => {
                  !current.includes(em.ID)
                    ? setCurrent([...current, em.ID])
                    : deleteFromGrid(em.ID);
                }}
                index={"product" + index}
                style={
                  current.includes(em.ID)
                    ? { background: "#0370f3", color: "#fff" }
                    : {}
                }
              >
                <img src={em.Photo.split(" ")[0] || ""} alt="" />
                <p>{em.Title}</p>
              </div>
            );
          })}
    </div>
  );
}

//Главный компонент
export default function FormNew({ type, products, myParam, setOpen }) {
  const [user, setUser] = useContext(User);
  const [html, setHtml] = useState("");

  const [currentProduct, setCurrentProduct] = useState({
    BlockValue: "",
    Comment: "",
    IdOwner: user.ID,
    IdPage: myParam,
    Type: "",
    Title: "",
  });

  useEffect(() => {
    setCurrentProduct({
      BlockValue: "",
      Comment: "",
      IdOwner: user.ID,
      IdPage: myParam,
      Type: type,
    });
  }, [type]);
  useEffect(() => {
    setCurrentProduct({
      ...currentProduct,
      BlockValue: html,
    });
  }, [html]);
  const [itemsSearch, setItemSearch] = useState("");
  return (
    <div className="FormNew">
      {type && (
        <div className="formInfo">
          {/*  Заголовок */}
          {(type == "grid" || type == "slider") && (
            <h3>Выберите товары из списка</h3>
          )}
          {type == "html" && <h3>Редактор кода HTML</h3>}

          {(type == "grid" || type == "slider") && (
            <input
              type="text"
              value={itemsSearch}
              onChange={(e) => {
                setItemSearch(e.target.value);
              }}
              placeholder="Поиск"
            />
          )}

          {(type == "grid" || type == "slider") && (
            <p>
              Выбранно
              <span>
                {currentProduct.BlockValue &&
                isJsonString(currentProduct.BlockValue)
                  ? JSON.parse(currentProduct.BlockValue).length
                  : 0}
              </span>
              элементов
            </p>
          )}
          {type == "slider" && (
            <input
              type="text"
              placeholder="Название подборки"
              value={currentProduct.Title}
              onChange={(e) =>
                setCurrentProduct({ ...currentProduct, Title: e.target.value })
              }
            />
          )}
          <div
            className="saveB"
            style={
              currentProduct.BlockValue.length != 0
                ? {}
                : {
                    opacity: "0.5",
                    pointerEvents: "none",
                  }
            }
            onClick={() => {
              saveBlocks(currentProduct).then((status) => {
                if (status == 200) {
                  setOpen(false);
                }
              });
            }}
          >
            Добавить блок
          </div>
        </div>
      )}
      {type == "grid" && (
        <div>
          <GridForm
            products={products}
            setCurrentProduct={setCurrentProduct}
            currentProduct={currentProduct}
            itemsSearch={itemsSearch}
          ></GridForm>
        </div>
      )}
      {type == "html" && (
        <div className="htmlRed">
          <CodeMirror
            value={currentProduct.BlockValue}
            style={{
              maxHeight: "40vw",
              overflowY: "scroll",
            }}
            height="40vw"
            extensions={[javascript({ jsx: true })]}
            onChange={setHtml}
          />
        </div>
      )}
      {type == "slider" && (
        <div>
          <GridForm
            products={products}
            setCurrentProduct={setCurrentProduct}
            currentProduct={currentProduct}
            itemsSearch={itemsSearch}
          ></GridForm>
        </div>
      )}
    </div>
  );
}
