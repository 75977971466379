import "./style.css";
import logo from "./logo.svg";

import { Context } from "../Context";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { routes } from "../routes";
import { Voice } from "../Voice";
import homea from "./img/homea.svg";
import sales from "./img/sales.svg";
import marketing from "./img/marketing.svg";
import pagesimg from "./img/pages.svg";
import analis from "./img/analitics.svg";
import settings from "./img/settings.svg";
import exit from "./img/exit.svg";
import arr from "./img/arr.svg";
import marketinga from "./img/marketinga.svg";
import StarIcon from "@mui/icons-material/Star";
import salesa from "./img/salesa.svg";
import products from "./img/products.svg";
import productsa from "./img/productsa.svg";
import analisa from "./img/analiticsa.svg";
import home from "./img/home.svg";
import i1 from "./img/1.svg";
import i2 from "./img/2.svg";
import i3 from "./img/3.svg";
import i4 from "./img/4.svg";
import i5 from "./img/5.svg";
import i6 from "./img/6.svg";
import i7 from "./img/i7.svg";
import tg from "./img/tg.svg";
import { Fade } from "react-reveal";
import { Store } from "react-notifications-component";
import { AdminData } from "../Contexts/AdminData";
import team from "./img/team.svg";

import SettingsSuggestOutlinedIcon from "@mui/icons-material/SettingsSuggestOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import EqualizerOutlinedIcon from "@mui/icons-material/EqualizerOutlined";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import WindowIcon from "@mui/icons-material/Window";
import TelegramIcon from "@mui/icons-material/Telegram";
import PaidIcon from "@mui/icons-material/Paid";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupsIcon from "@mui/icons-material/Groups";
import LogoutIcon from "@mui/icons-material/Logout";
import AddchartRoundedIcon from "@mui/icons-material/AddchartRounded";
import AddBusinessOutlinedIcon from "@mui/icons-material/AddBusinessOutlined";
import WindowOutlinedIcon from "@mui/icons-material/WindowOutlined";
import AppShortcutOutlinedIcon from "@mui/icons-material/AppShortcutOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import CurrencyExchangeOutlinedIcon from "@mui/icons-material/CurrencyExchangeOutlined";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import AppShortcutRoundedIcon from "@mui/icons-material/AppShortcutRounded";
import MarkEmailUnreadRoundedIcon from "@mui/icons-material/MarkEmailUnreadRounded";
import StoreRoundedIcon from "@mui/icons-material/StoreRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import Diversity3RoundedIcon from "@mui/icons-material/Diversity3Rounded";
import EqualizerRoundedIcon from "@mui/icons-material/EqualizerRounded";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
export default function Navbar() {
  const [settingss, openSettings] = useState(false);
  const [pages, setPages] = useContext(AdminData);
  const Navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  return (
    <div className="navbar">
      <div className="navTo">
        <div className="llogo">
          <AddchartRoundedIcon></AddchartRoundedIcon>
          <p className="ibRf">
            ИВ.РФ <span>Инвестиции в будущее</span>
          </p>
        </div>
        <div className="navRaz"></div>
        <span className="menuElemsNav">
          <div
            className={`home ${
              location.pathname == routes.main && "homeActive"
            }`}
            onClick={() => Navigate(routes.main)}
          >
            <GridViewRoundedIcon />
            <p>Главная</p>
          </div>

          <div
            className={`home ${
              location.pathname == routes.orders && "homeActive"
            }`}
            onClick={() => Navigate(routes.orders)}
          >
            <MarkEmailUnreadRoundedIcon />
            <p>Заказы</p>
          </div>
          <div
            className={`home menu ${
              (location.pathname == routes.products ||
                location.pathname == routes.pageList ||
                location.pathname == routes.themes ||
                location.pathname == routes.categories ||
                location.pathname == routes.users) &&
              "homeActive"
            }`}
            onClick={() => setOpen(!open)}
          >
            <StoreRoundedIcon />
            <p>Магазин</p>
            <KeyboardArrowDownIcon
              style={{ transform: open ? "rotate(180deg)" : "" }}
              className="arrowDown"
            ></KeyboardArrowDownIcon>
          </div>

          <div className="showShop" style={{ maxHeight: open ? "300px" : "0" }}>
            <div>
              <Link
                to={routes.products}
                style={
                  location.pathname == routes.products
                    ? { fontWeight: "600" }
                    : {}
                }
              >
                <span>•</span>
                Товары
              </Link>
              <Link
                to={routes.pageList}
                style={
                  location.pathname == routes.pageList
                    ? { fontWeight: "600" }
                    : {}
                }
              >
                <span>•</span>Страницы
              </Link>
              <Link
                to={routes.themes}
                style={
                  location.pathname == routes.themes
                    ? { fontWeight: "600" }
                    : {}
                }
              >
                <span>•</span>Тема
              </Link>
              <Link
                to={routes.categories}
                style={
                  location.pathname == routes.categories
                    ? { fontWeight: "600" }
                    : {}
                }
              >
                <span>•</span>Категории
              </Link>
              <Link
                to={routes.users}
                style={
                  location.pathname == routes.users ? { fontWeight: "600" } : {}
                }
              >
                <span>•</span>Пользователи
              </Link>
            </div>
          </div>

          {/* <div className="home" onClick={() => Navigate(routes.main)}>
          <div style={{ width: "40px" }} className="lordI">
            <img src={marketing} alt="" />
          </div>
          <p>Маркетинг</p>
        </div> */}
          <div
            className={`home ${
              location.pathname == routes.profile && "homeActive"
            }`}
            onClick={() => Navigate(routes.profile)}
          >
            <SettingsRoundedIcon />
            <p>Настройки</p>
          </div>
          <div
            className={`home ${
              location.pathname == routes.team && "homeActive"
            }`}
            onClick={() => Navigate(routes.team)}
          >
            <Diversity3RoundedIcon />
            <p>Сотрудники</p>
          </div>

          <div
            className="home"
            style={{ opacity: "0.6" }}
            onClick={() => {
              Store.addNotification({
                title: `Аналитика`,
                message: `Данный раздел находится в разработке`,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__bounceIn"],
                animationOut: ["animate__animated", "animate__backOutUp"],
                dismiss: {
                  duration: 4000,
                  onScreen: true,
                },
              });
            }}
          >
            <EqualizerRoundedIcon />
            <p>Аналитика</p>
          </div>
          <div
            className={`home ${
              location.pathname == routes.webapp && "homeActive"
            }`}
            style={{ opacity: "0.6" }}
            onClick={() => {
              Store.addNotification({
                title: `WebApp`,
                message: `Данный раздел находится в разработке`,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__bounceIn"],
                animationOut: ["animate__animated", "animate__backOutUp"],
                dismiss: {
                  duration: 4000,
                  onScreen: true,
                },
              });
            }}
          >
            <AppShortcutRoundedIcon />
            <p>WebApp</p>
          </div>
        </span>
        <div className="needHelp">
          <StarIcon></StarIcon>
          <p>Нужна помощь?</p>
          <span>Напишите нам</span>
          <div className="write">Написать</div>
        </div>
        <div
          className="home exist"
          onClick={() => {
            Navigate(routes.login);
            localStorage.clear();
            window.location.reload();
          }}
        >
          <LogoutIcon></LogoutIcon>
          <p>Выйти</p>
        </div>
      </div>
    </div>
  );
}
