import { domen } from "./consts";
const id = localStorage.id;

//Загрузка изображения на сервер

export async function uploadImage(file) {
  const data = new FormData();

  data.append("file", file);
  const response = await fetch(domen + "/avatar.php", {
    method: "post",
    body: data,
  });
  return response.json();
}

export async function updateLogo(path, id) {
  const data = new FormData();

  data.append("path", path);
  data.append("id", id);
  const response = await fetch(domen + "/showcase/updateLg.php", {
    method: "post",
    body: data,
  });
  return response.json();
}

export async function updateAction(path, id) {
  const data = new FormData();

  data.append("path", path);
  data.append("id", id);
  const response = await fetch(domen + "/showcase/updateAction.php", {
    method: "post",
    body: data,
  });
  return response.json();
}

export async function updateInfo(user, id1, id2, id3, id4) {
  const data = new FormData();

  data.append("tg", user.Tg);
  data.append("wt", user.Wt);
  data.append("telW", user.TelWork);
  data.append("timeW", user.TimeWork);
  data.append("token", user.tokenBot);
  data.append("RightPage", user.RightPage);
  data.append("ColumnT", user.ColumnT);
  data.append("id", user.ID);
  data.append("idActions", JSON.stringify([id1, id2, id3, id4]));
  const response = await fetch(domen + "/showcase/updateInfo.php", {
    method: "post",
    body: data,
  });
  return response.json();
}

//Получить инфо пользователя
export async function getInfoUser(id) {
  let form = new FormData();
  form.append("ID", id);
  const response = await fetch(domen + "/showcase/getInfoUser.php", {
    method: "POST",
    body: form,
  });

  return await response.json();
}

//Получение списка заказов
export async function getOrders(id) {
  let form = new FormData();
  form.append("id", id);
  const response = await fetch(domen + "/getOrders.php", {
    method: "POST",
    body: form,
  });

  return await response.json();
}

//Получение деталей заказа
export async function getOrder(idT) {
  let form = new FormData();
  form.append("id", id);
  form.append("idT", idT);
  const response = await fetch(domen + "/getOrder.php", {
    method: "POST",
    body: form,
  });

  return await response.json();
}

//Форматирование даты
export const formatDate = (date) => {
  let d = new Date(date);
  let month = (d.getMonth() + 1).toString();
  let day = d.getDate().toString();
  let year = d.getFullYear();
  if (month.length < 2) {
    month = "0" + month;
  }
  if (day.length < 2) {
    day = "0" + day;
  }
  return [year, month, day].join("-");
};

//Изменить статус заказа

export async function updateStatus(idOrder, status) {
  let form = new FormData();
  form.append("id", idOrder);
  form.append("status", status);
  const response = await fetch(domen + "/cardsApi/updateOrder.php", {
    method: "POST",
    body: form,
  });
}

//Получение информации о карточке товара

export async function getCard(idCard) {
  let form = new FormData();
  form.append("ID", idCard);
  const response = await fetch(domen + "/getCard.php", {
    method: "POST",
    body: form,
  });

  return await response.json();
}

//Получение заказов пользователя

export async function getOrdersUser(tel) {
  let form = new FormData();
  form.append("tel", tel);
  form.append("id", id);
  const response = await fetch(domen + "/getOrdersByTel.php", {
    method: "POST",
    body: form,
  });

  return await response.json();
}

//Получение чата с пользователем

export async function getChat(idUser) {
  let form = new FormData();
  form.append("idOwner", id);
  form.append("idUser", idUser);

  const response = await fetch(domen + "/showcase/getChat.php", {
    method: "POST",
    body: form,
  });
  return response.json();
}

//Отправка сообщения

export async function sendMessage(text) {
  let form = new FormData();
  form.append("idOwner", id);
  form.append("time", new Date().toString());
  form.append("Bot", 1);
  form.append("text", text);
  form.append("idUser", JSON.parse(localStorage.user).ID);
  const response = await fetch(domen + "/showcase/sendMessage.php", {
    method: "POST",
    body: form,
  });
  return response.status;
}

//Получения информации о USER через телефон

export async function getUserByPhone(phone) {
  let form = new FormData();

  form.append("phone", phone);

  const response = await fetch(domen + "/showcase/getUserByPhone.php", {
    method: "POST",
    body: form,
  });
  return response.json();
}

//Получение списка тем

export async function getThemes(idTheme) {
  let form = new FormData();
  form.append("id", idTheme);
  const response = await fetch(
    "https://comeback-team.ru/showcase/Themes/getThemes.php",
    {
      method: "POST",
      body: form,
    }
  );

  return await response.json();
}

//Обновление темы

export async function updateTheme(idTheme, arr) {
  let form = new FormData();
  form.append("id", idTheme);
  form.append("arr", arr);
  const response = await fetch(
    "https://comeback-team.ru/showcase/Themes/updateTheme.php",
    {
      method: "POST",
      body: form,
    }
  );

  return await response.json();
}

//Получить товар

export async function getTovar() {
  let formData = new FormData();
  formData.append("idOwner", localStorage.id);
  const response = await fetch("https://comeback-team.ru/admin/Tovar.php", {
    method: "POST",
    body: formData,
  });
  return await response.json();
}

export async function saveBlocks(form) {
  let formData = new FormData();
  formData.append("idOwner", form.IdOwner);
  formData.append("timeChange", new Date());
  formData.append("idPage", form.IdPage);
  if (form.Title && form.Title.length > 0) {
    formData.append(
      "value",
      JSON.stringify({
        title: form.Title,
        value: JSON.parse(form.BlockValue),
      })
    );
  } else {
    formData.append("value", form.BlockValue);
  }
  formData.append("comment", form.comment);
  formData.append("type", form.Type);

  const response = await fetch(
    "https://comeback-team.ru/showcase/blocks/setBlocks.php",
    {
      method: "POST",
      body: formData,
    }
  );
  return response.status;
}

export async function getBlocks() {
  let formData = new FormData();
  formData.append("idOwner", localStorage.id);
  const response = await fetch(
    "https://comeback-team.ru/showcase/blocks/getBlocks.php",
    {
      method: "POST",
      body: formData,
    }
  );
  return response.json();
}

//Удаление блока

export async function deleteBlock(id) {
  let formData = new FormData();
  formData.append("ID", id);
  const response = await fetch(
    "https://comeback-team.ru/showcase/blocks/deleteBlock.php",
    {
      method: "POST",
      body: formData,
    }
  );
  return response.status;
}

//Апдейт блока

export async function updateBlock(id, value) {
  let formData = new FormData();
  formData.append("ID", id);
  formData.append("value", value);
  const response = await fetch(
    "https://comeback-team.ru/showcase/blocks/updateBlock.php",
    {
      method: "POST",
      body: formData,
    }
  );
  return response.status;
}

export async function getUserData() {
  let form = new FormData();
  form.append("tel", localStorage.tel);
  form.append("password", localStorage.password);
  const response = await fetch("https://comeback-team.ru/auth.php", {
    method: "post",
    body: form,
  });
  return await response.json();
}

//Страница добавление

export async function savePage(form) {
  let formData = new FormData();
  for (let key in form) {
    formData.append(key, form[key]);
  }
  formData.append("idOwner", localStorage.id);

  const response = await fetch(
    "https://comeback-team.ru/showcase/pages/addPage.php",
    {
      method: "POST",
      body: formData,
    }
  );
  return response.status;
}

export async function getPages() {
  let form = new FormData();
  form.append("idOwner", localStorage.id);

  const response = await fetch(
    "https://comeback-team.ru/showcase/pages/getPages.php",
    {
      method: "post",
      body: form,
    }
  );
  return await response.json();
}

export async function getBlocksForPage(key) {
  let form = new FormData();
  form.append("idOwner", localStorage.id);
  form.append("idPage", key);
  const response = await fetch(
    "https://comeback-team.ru/showcase/blocks/getBlocksForPage.php",
    {
      method: "post",
      body: form,
    }
  );
  return await response.json();
}

export async function updateCss(value) {
  let form = new FormData();
  form.append("ID", localStorage.id);
  form.append("value", value);
  const response = await fetch(
    "https://comeback-team.ru/showcase/updateCss.php",
    {
      method: "post",
      body: form,
    }
  );
  return response.status;
}

//Получить пользователей

export async function getUsers(val) {
  let form = new FormData();

  form.append("id", val);
  const response = await fetch(
    "https://comeback-team.ru/showcase/getUsers.php",
    {
      method: "post",
      body: form,
    }
  );
  return response.json();
}

export async function getProducts() {
  let formData = new FormData();
  formData.append("idOwner", localStorage.id);
  const response = await fetch("https://comeback-team.ru/admin/Tovar.php", {
    method: "POST",
    body: formData,
  });
  return response.json();
}
export async function getGroups() {
  let formData = new FormData();
  formData.append("id", localStorage.id);
  const response = await fetch(
    "https://comeback-team.ru/showcase/category/getGroups.php",
    {
      method: "POST",
      body: formData,
    }
  );
  return response.json();
}
export async function getCategories(cat) {
  let formData = new FormData();
  formData.append("id", cat);
  const response = await fetch(
    "https://comeback-team.ru/showcase/category/getCats.php",
    {
      method: "POST",
      body: formData,
    }
  );
  return response.json();
}
