import "./style.css";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CodeOutlinedIcon from "@mui/icons-material/CodeOutlined";
import AppsOutlinedIcon from "@mui/icons-material/AppsOutlined";
import ThreeDRotationOutlinedIcon from "@mui/icons-material/ThreeDRotationOutlined";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
import { useState } from "react";
import FormNew from "../FormNew";
export default function AddNew({ myParam, setOpen, products }) {
  const [type, currentType] = useState("");
  return (
    <div className="addNew">
      <div className="closeAdd" onClick={() => setOpen(false)}>
        <CloseOutlinedIcon></CloseOutlinedIcon>
      </div>
      <h4>Выберите блок для добавления:</h4>
      <div className="blocksList">
        <div
          className="blockItem"
          style={{
            border: type == "html" ? "1px solid #0370f3" : "",
          }}
          onClick={() => {
            currentType("html");
          }}
        >
          <CodeOutlinedIcon></CodeOutlinedIcon>
          <p>HTML блок</p>
        </div>
        <div
          className="blockItem"
          onClick={() => {
            currentType("grid");
          }}
          style={{
            border: type == "grid" ? "1px solid #0370f3" : "",
          }}
        >
          <AppsOutlinedIcon></AppsOutlinedIcon>
          <p>Сетка товаров</p>
        </div>
        <div
          className="blockItem"
          onClick={() => {
            currentType("3d");
          }}
          style={{
            border: type == "3d" ? "1px solid #0370f3" : "",
          }}
        >
          <ThreeDRotationOutlinedIcon></ThreeDRotationOutlinedIcon>
          <p>3D слайдер баннеры</p>
        </div>
        <div
          className="blockItem"
          onClick={() => {
            currentType("slider");
          }}
          style={{
            border: type == "slider" ? "1px solid #0370f3" : "",
          }}
        >
          <ChangeCircleOutlinedIcon></ChangeCircleOutlinedIcon>
          <p>Слайдер товаров</p>
        </div>
      </div>
      <FormNew
        myParam={myParam}
        setOpen={setOpen}
        type={type}
        products={products}
      ></FormNew>
    </div>
  );
}
