import { useEffect } from "react";
import "./style.css";
import { useState } from "react";
import { getProducts } from "../../Api";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import ProductCard from "./ProductCard";
const gridValue = "1.5vw 3vw 3.5fr 1fr 1fr 1fr 1fr 1fr 1fr";

export default function ProductsPage() {
  const [products, setProducts] = useState([]);
  const [search, setSearch] = useState("");

  function sortArr(arr, key, type) {
    setProducts(
      [...arr].sort((a, b) => {
        const nameA = a[key].toUpperCase(); // ignore upper and lowercase
        const nameB = b[key].toUpperCase(); // ignore upper and lowercase
        if (type == "num") {
          if (Number(nameA) < Number(nameB)) {
            return -1;
          }
          if (Number(nameA) > Number(nameB)) {
            return 1;
          }
        }
        if (type == "str") {
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
        }
        // names must be equal
        return 0;
      })
    );
  }
  useEffect(() => {
    getProducts().then((data) => {
      setProducts(data.reverse());
    });
  }, []);
  return (
    <div className="container productsPage">
      <ProductCard></ProductCard>

      <div className="productsBody">
        <div className="prodBodyTitle">
          <div className="leftProdTitle">
            <h3>Все товары</h3>
            <p>
              Количество товаров: {products.length}
              <br />
              Импортируйте свой каталог, используя EXEL или CSV формат
            </p>
          </div>
          <div className="rightProdTitle">
            <div className="newplan">+ Новый товар</div>
            <div className="opacButton">Импорт</div>
            <div className="opacButton">Експорт</div>
          </div>
        </div>
        <div className="productsSearchPanel">
          <input
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Поиск"
          />
        </div>
        <div className="tableProducts">
          <div
            className="gridTable gridTitle"
            style={{ gridTemplateColumns: gridValue }}
          >
            <div></div>
            <div
              onClick={() => {
                sortArr(products, "Title", "str");
              }}
            >
              Товар <SwapVertIcon></SwapVertIcon>
            </div>
            <div></div>
            <div
              onClick={() => {
                sortArr(products, "Type", "str");
              }}
            >
              Категории <SwapVertIcon></SwapVertIcon>
            </div>
            <div
              onClick={() => {
                sortArr(products, "Price", "num");
              }}
            >
              Цена <SwapVertIcon></SwapVertIcon>
            </div>
            <div
              onClick={() => {
                sortArr(products, "ID", "str");
              }}
            >
              ID <SwapVertIcon></SwapVertIcon>
            </div>
            <div>Количество</div>
            <div
              onClick={() => {
                sortArr(products, "availability", "str");
              }}
            >
              Статус <SwapVertIcon></SwapVertIcon>
            </div>
            <div>Действия</div>
          </div>
          {products &&
            products.length > 0 &&
            products
              .filter((em) =>
                em.Title.toLowerCase().includes(search.toLowerCase())
              )
              .map((em, index) => {
                let photo = em.Photo.split(" ")[0];
                return (
                  <div
                    className="gridTable gridRow"
                    key={"product" + index}
                    style={{ gridTemplateColumns: gridValue }}
                  >
                    <div>
                      <input type="checkbox"></input>
                    </div>
                    <div className="productImage">
                      <img src={photo}></img>
                    </div>
                    <div className="productName">{em.Title}</div>
                    <div className="userName">{em.Type}</div>
                    <div className="userName">
                      {Number(em.Price).toLocaleString()} ₽
                    </div>
                    <div className="userName">{em.ID}</div>
                    <div className="userName">∞</div>
                    <div className="userName">
                      {/* {em.availability} */}{" "}
                      <p
                        className="availibity"
                        style={
                          em.availability == 0
                            ? {
                                background: "rgb(252, 151, 151)",
                                color: "rgb(189, 0, 0)",
                              }
                            : {}
                        }
                      >
                        {em.availability == 1 ? "Виден" : "Скрыт"}
                      </p>
                    </div>
                    <div className="userName iconsProduct">
                      <RemoveRedEyeIcon></RemoveRedEyeIcon>
                      <EditIcon></EditIcon>
                      <DeleteIcon></DeleteIcon>
                    </div>
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );
}
