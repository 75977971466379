import { useContext, useState } from "react";
import SelectMultiply from "../../Components/SelectMultiply";
import "./style.css";
import { User } from "../../Contexts/User";
import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
const arr = [
  { Title: "Заявки", ID: "0" },
  { Title: "Пользователи", ID: "1" },
  { Title: "Витрина", ID: "2" },
];
const label = { inputProps: { "aria-label": "Checkbox demo" } };
export default function Workers() {
  const [select, setSelect] = useState("");
  const [user, setUser] = useContext(User);
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [password, setPassword] = useState("");
  console.log(select);
  function addWorker() {
    const formData = new FormData();
    formData.append("FIO", name);
    formData.append("tel", user.Телефон);
    formData.append("password", password);
    formData.append("email", user.email);
    formData.append("company", user.Компания);
    formData.append("idOwner", user.ID);
    fetch("https://comeback-team.ru/registration.php", {
      method: "post",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        if (data == "Ok") {
        } else {
        }
        //document.location.href = "htts://webby-digital.ru/thanks.html";
        //else alert(data);
      });
  }
  return (
    <div className="container workers">
      <h4>Добавить пользователя</h4>
      <div className="addUser">
        <TextField
          value={name}
          onChange={(e) => setName(e.target.value)}
          id="filled-basic"
          label="ФИО"
          fullWidth
          variant="filled"
          sx={{ m: 1, minWidth: 120 }}
        />
        <TextField
          value={role}
          onChange={(e) => setRole(e.target.value)}
          id="filled-basic"
          label="Роль"
          fullWidth
          variant="filled"
          sx={{ m: 1, minWidth: 120 }}
        />
        <TextField
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          id="filled-basic"
          label="Пароль"
          fullWidth
          variant="filled"
          sx={{ m: 1, minWidth: 120 }}
        />

        {/*   <SelectMultiply
          select={select}
          setSelect={setSelect}
          arr={arr}
        ></SelectMultiply> */}
        <FormControl fullWidth variant="filled" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="demo-simple-select-label">Права</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={select}
            label="Age"
            onChange={(e) => setSelect(e.target.value)}
          >
            {arr.map((em) => {
              return <MenuItem value={em.Title}>{em.Title}</MenuItem>;
            })}
          </Select>
        </FormControl>
        <Button sx={{ m: 1, minWidth: 160 }} variant="contained">
          Добавить
        </Button>
      </div>
    </div>
  );
}
