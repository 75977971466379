import { useContext, useEffect, useState } from "react";
import "./style.css";
import { User } from "../../Contexts/User";
import { getUsers } from "../../Api";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import RateReviewIcon from "@mui/icons-material/RateReview";
import SmsIcon from "@mui/icons-material/Sms";
import BlockIcon from "@mui/icons-material/Block";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
export default function Users() {
  const [admin, setAdmin] = useContext(User);
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [openPanel, setOpenPanel] = useState(true);
  useEffect(() => {
    getUsers(admin.ID).then((data) => {
      setUsers(data);
    });
  }, [admin.ID]);
  return (
    <div className="container usersCont">
      {!openPanel && (
        <div>
          {" "}
          <button className="openPanel" onClick={() => setOpenPanel(true)}>
            Развернуть панель{" "}
            <KeyboardDoubleArrowUpIcon></KeyboardDoubleArrowUpIcon>
          </button>
        </div>
      )}
      <div
        className="shadowWalk"
        style={{
          transform: openPanel ? "" : "translateY(-50vw)",
          maxHeight: openPanel ? "30vw" : "0",
          margin: openPanel ? "1.5vw 0" : "0",
        }}
      >
        <div
          className="panelUp"
          onClick={() => {
            setOpenPanel(!openPanel);
          }}
        >
          Свернуть панель{" "}
          <KeyboardDoubleArrowUpIcon></KeyboardDoubleArrowUpIcon>
        </div>
        <div className="usersStat">
          <div>
            <div className="dotsMore">•••</div>
            <div className="userStatIcon">
              <AccountCircleIcon />
            </div>
            <div className="usersStatCount">430</div>
            <div className="usersTitleStat">
              <p>Активных пользователей</p>
              <span>+10%</span>
            </div>
          </div>
          <div>
            <div className="dotsMore">•••</div>
            <div className="userStatIcon">
              <LocalMallIcon></LocalMallIcon>
            </div>
            <div className="usersStatCount">1248</div>
            <div className="usersTitleStat">
              <p>Товаров в корзинах</p>
              <span>+37%</span>
            </div>
          </div>
          <div>
            <div className="dotsMore">•••</div>
            <div className="userStatIcon">
              <RateReviewIcon />
            </div>
            <div className="usersStatCount">44</div>
            <div className="usersTitleStat">
              <p>Отзыва написано</p>
              <span>+12%</span>
            </div>
          </div>
          <div>
            <div className="dotsMore">•••</div>
            <div className="userStatIcon">
              <AccountBalanceWalletIcon></AccountBalanceWalletIcon>
            </div>
            <div className="usersStatCount">26 500₽</div>
            <div className="usersTitleStat">
              <p>Самый большой заказ</p>
              <span>+15%</span>
            </div>
          </div>
        </div>
        <div className="dayPlan">
          <h3>Ежедневный план</h3>
          <div className="progressTitle">
            <p>
              Количество заказов
              <span>80%</span>
            </p>
            <div className="progB">
              <div className="load" style={{ width: "80%" }}></div>
            </div>
          </div>
          <div className="progressTitle">
            <p>
              Средняя стоимость корзины
              <span>132%</span>
            </p>
            <div className="progB">
              <div
                className="load"
                style={{
                  width: "100%",
                  background:
                    "linear-gradient(to right,rgb(38, 210, 219) 0%,rgb(107, 150, 250) 100%)",
                }}
              ></div>
            </div>
          </div>
          <div className="progressTitle">
            <p>
              Новые пользователи
              <span>60%</span>
            </p>
            <div className="progB">
              <div
                className="load"
                style={{
                  width: "60%",
                  background:
                    "linear-gradient(to right, #fa709a 0%, #fee140 100%)",
                }}
              ></div>
            </div>
          </div>
          <div className="txtAndButton">
            <p>
              Ваши показатели сегодня на <b>14%</b> выше, <br /> чем в среднем
              за последний месяц
            </p>
            <div className="newplan">Изменить план</div>
          </div>
        </div>
      </div>{" "}
      <h2 className="descTitle">Пользователи</h2>
      <p className="descText">
        Здесь вы можете просматривать информацию о пользователях, а также <br />{" "}
        вести с ними диалог через встроенный чат
      </p>
      <div className="usersTable">
        <div className="gridTable gridTitle">
          <div></div>
          <div>Имя</div>
          <div></div>
          <div>Телефон</div>
          <div>Email</div>
          <div>Дата регистрации</div>
          <div>Последний on-line</div>
          <div>ID</div>
          <div></div>
          <div></div>
        </div>

        <div>
          {users &&
            users.length > 0 &&
            users.map((em, index) => {
              return (
                <div className="gridTable gridRow">
                  <div>
                    <input type="checkbox" name="" id="" />
                  </div>
                  <div>
                    <img className="userAvatar" src={em.Photo} alt="" />
                  </div>
                  <p className="userName">{em.FIO}</p>
                  <div className="userPhone">{em.Phone || "-"}</div>
                  <div className="userName">{em.Email || "-"}</div>
                  <div className="userName">
                    {em.dateReg.length > 0
                      ? new Date(em.dateReg).toLocaleDateString()
                      : "-"}
                  </div>
                  <div className="userName">
                    {new Date().toLocaleDateString()}
                  </div>
                  <span>{em.ID}</span>
                  <div>
                    <SmsIcon></SmsIcon>
                  </div>
                  <div>
                    <BlockIcon></BlockIcon>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
