import { useEffect, useState } from "react";
import "./style.css";
import { getThemes, updateTheme } from "../../Api";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-creative";

// import required modules
import { EffectCreative } from "swiper/modules";
import ThemeEm from "./ThemeEm";
import { Store } from "react-notifications-component";
export default function ThemesPage() {
  const [themes, setThemes] = useState([]);
  const [activeSlide, setActiveSlide] = useState(0);
  console.log(activeSlide);
  function themeGET() {
    getThemes(localStorage.id).then((data) => {
      setThemes(
        data.map((em) => {
          return {
            name: em.name,
            ID: em.ID,
            idOwner: em.idOwner,
            params: em.params.split(";").map((elem) => {
              return {
                name: elem.substring(0, elem.indexOf(":")),
                value: elem
                  .substring(elem.indexOf(":"), elem.length)
                  .replace(":", "")
                  .trim(),
              };
            }),
          };
        })
      );
    });
  }
  useEffect(() => {
    themeGET();
  }, []);
  function Notification(text) {
    Store.addNotification({
      title: `Настройка тем`,
      message: text,
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__bounceIn"],
      animationOut: ["animate__animated", "animate__backOutUp"],
      dismiss: {
        duration: 4000,
        onScreen: true,
      },
    });
  }
  function saveTheme() {
    let inputs = document.getElementsByClassName(`themeIn${activeSlide}`);
    console.log(inputs);
    let str = [];
    for (let i = 0; i < inputs.length; i++) {
      str.push(
        inputs[i].name.replaceAll(" ", "").replaceAll("\r\n", "") +
          ":" +
          inputs[i].value.replace(/\s+/g, " ")
      );
    }
    updateTheme(themes[activeSlide].ID, str.join(";")).then((data) => {
      if (data == "ok") {
        themeGET();
        Notification("Цвет обновлен");
      }
    });
  }
  return (
    <div className="themesPage container">
      <h2 className="descTitle">Темы магазина</h2>
      <p className="descText">
        Темы используются для задания расцветки основных блоков в вашем
        магазине. <br />
        Раздел находится в стадии адаптации для администраторов
      </p>
      <div className="themesBlock">
        {/*    <div
          className="themeHandler"
          onClick={() => activeSlide != 0 && setActiveSlide(activeSlide - 1)}
          style={{
            transform: "rotate(180deg)",
            opacity: activeSlide == 0 ? "0.5" : "1",
          }}
        >
          ᐳ
        </div> */}
        <div className="thmCont">
          {themes.length > 0 &&
            themes.map((em, index) => {
              return (
                <div key={index} className="thmEm">
                  <p className="themeTitle">{em.name}</p>
                  <div className="colorsCont">
                    {em.params &&
                      em.params.length > 0 &&
                      em.params.map((elem, ind) => {
                        return (
                          <ThemeEm elem={elem} ids={index} key={ind}></ThemeEm>
                        );
                      })}
                  </div>
                </div>
              );
            })}
        </div>
        {/*   <div
          className="themeHandler"
          style={{ opacity: activeSlide == themes.length - 1 ? "0.5" : "1" }}
          onClick={() => swiper.slideNext()}
        >
          ᐳ
        </div> */}
      </div>
      <div className="buttonsTheme">
        <div onClick={() => saveTheme()}> Сохранить</div>
      </div>
    </div>
  );
}
