export const routes = {
  login: "/login",
  register: "/register",
  main: "/main",
  adminMainTable: "/adminMainTable",
  dogovor: "/dogovor",
  actions: "/actions",
  services: "/services",
  doctors: "/doctors",
  contacts: "/contacts",
  clients: "/clients",
  objects: "/objects",
  orders: "/orders",
  tovar: "/tovar",
  chat: "/chat",
  edit: "/edit",
  orderDetails: "/orderDetails",
  MainPageShop: "/mainPageShop",
  razdel: "/razdel",
  start: "/",
  themes: "/themes",
  profile: "/profile",
  mainEdit: "/mainEdit",
  createPage: "/createPage",
  changePage: "/changePage",
  cssPage: "/cssPage",
  team: "/team",
  webapp: "/webapp",
  pageList: "/pagelist",
  users: "/users",
  products: "/products",
  categories: "/categories",
};
