import { useContext, useEffect, useState } from "react";
import SelectComp from "../../Components/Select";
import "./style.css";
import { FileUploader } from "react-drag-drop-files";
import SelectMultiply from "../../Components/SelectMultiply";
import {
  deleteBlock,
  getBlocks,
  getBlocksForPage,
  getTovar,
  getUserData,
  saveBlocks,
  updateBlock,
} from "../../Api";
import { User } from "../../Contexts/User";
import Notification from "../../Components/Notification";
import notification from "../../Components/Notification";
import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import { Store } from "react-notifications-component";
import { Fade } from "react-reveal";
import { useDrag } from "react-dnd";
import { TextField } from "@mui/material";
import AppsIcon from "@mui/icons-material/Apps";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import WebOutlinedIcon from "@mui/icons-material/WebOutlined";
import AddNew from "./AddNew";
import CodeOutlinedIcon from "@mui/icons-material/CodeOutlined";
import AppsOutlinedIcon from "@mui/icons-material/AppsOutlined";
import ThreeDRotationOutlinedIcon from "@mui/icons-material/ThreeDRotationOutlined";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
const arr = ["Товары", "Акции", "Слайдер", "Кастомный блок"];
const fileTypes = ["JPG", "PNG", "GIF", "WEBP", "WEBM", "MP4"];

function getDate(date) {
  return date.toISOString().slice(0, 19).replace(/-/g, "/").replace("T", " ");
}
export default function ChangePage() {
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get("key");
  const name = urlParams.get("name");
  const pth = urlParams.get("route");
  const [mainPageData, setMainPageDate] = useState([]);
  const [select, setSelect] = useState("Выберите элемент");
  const [open, setOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [search, setSearch] = useState("");
  useEffect(() => {
    getBlocksForPage(myParam).then((data) => {
      setMainPageDate(data);
    });
    getTovar().then((data) => {
      setProducts(data);
    });
  }, []);

  return (
    <div className="MainPageEditor container">
      {open && (
        <AddNew
          myParam={myParam}
          setOpen={setOpen}
          products={products}
        ></AddNew>
      )}
      <div className="contBody">
        <div className="headerContBody">
          <WebOutlinedIcon className="webs"></WebOutlinedIcon>
          <p>{name}</p>
          <input
            type="text"
            className="blockSearch"
            placeholder="Поиск"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <div className="headerContButtons">
            <div
              className="watchLink"
              onClick={() =>
                window.open(`https://витрина-игры.рф/${pth}`, "_blank")
              }
            >
              Просмотр страницы
            </div>
            <div className="addBl" onClick={() => setOpen(true)}>
              Добавить новый блок
            </div>
          </div>
        </div>
        <div className="blocksBlock">
          {mainPageData &&
            mainPageData.length > 0 &&
            mainPageData
              .filter((elem) =>
                elem.Comment.toLowerCase().includes(search.toLowerCase())
              )
              .map((em, index) => {
                return (
                  <div className="contChild">
                    {" "}
                    <h4>{index + 1}</h4>
                    {em.Type == "grid" && (
                      <AppsIcon className="childIcon appI" />
                    )}
                    {em.Type == "html" && (
                      <CodeOutlinedIcon className="childIcon appI" />
                    )}
                    {em.Type == "slider" && (
                      <ChangeCircleOutlinedIcon className="childIcon appI" />
                    )}
                    <p>
                      {em.Type == "html" && "HTML блок"}
                      {em.Type == "grid" && "Сетка товаров"}
                      {em.Type == "slider" && "Слайдер товаров"}
                    </p>
                    {/*   ПОЗИЦИИ */}
                    {em.Type == "grid" && (
                      <article>
                        {JSON.parse(em.BlockValue).length + " ед."}
                      </article>
                    )}
                    {em.Type == "slider" && (
                      <article>
                        {JSON.parse(em.BlockValue).value.length + " ед."}
                      </article>
                    )}
                    {em.Type == "grid" && (
                      <div className="imagesForType">
                        {JSON.parse(em.BlockValue).map((img, index) => {
                          console.log(products.filter((l) => l.ID == img));
                          return (
                            <img
                              style={{
                                left: index * 3 + "vw",
                              }}
                              src={
                                (products &&
                                  products.length > 0 &&
                                  products
                                    .filter((l) => l.ID == img)[0]
                                    .Photo.split(" ")[0]) ||
                                ""
                              }
                            ></img>
                          );
                        })}
                      </div>
                    )}
                    {em.Type == "slider" && (
                      <div className="imagesForType">
                        {JSON.parse(em.BlockValue).value.map((img, index) => {
                          console.log(products.filter((l) => l.ID == img));
                          return (
                            <img
                              style={{
                                left: index * 30 + "px",
                              }}
                              src={
                                (products &&
                                  products.length > 0 &&
                                  products
                                    .filter((l) => l.ID == img)[0]
                                    .Photo.split(" ")[0]) ||
                                ""
                              }
                            ></img>
                          );
                        })}
                      </div>
                    )}
                    <div className="changeBlock">
                      <div className="comment">
                        {em.Comment != "undefined" && em.Comment}
                      </div>
                      <span>
                        <span>Последнее редактирование:</span>
                        <i>
                          {em.ChangeTime.length > 0 &&
                            getDate(new Date(em.ChangeTime))}
                        </i>
                      </span>
                      <SettingsRoundedIcon className="childIcon deleteBlockSvg"></SettingsRoundedIcon>
                      <DeleteRoundedIcon
                        onClick={() => {
                          deleteBlock(em.ID).then((status) => {
                            if (status == 200) {
                              getBlocksForPage(myParam).then((data) => {
                                setMainPageDate(data);
                              });
                            }
                          });
                        }}
                        className="childIcon deleteBlockSvg"
                      ></DeleteRoundedIcon>
                    </div>
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );
}
