import "../admin.css";

import { Context } from "../../Context";
import { useContext, useEffect, useRef, useState } from "react";
import "./style.css";
import { Zoom } from "react-reveal";
import { Flip } from "react-reveal";
import Grafic from "../../Components/Chars";
import arrUp from "./img/up.svg";
import arrDown from "./img/down.svg";
import card from "./img/card.svg";
import CustomGr from "./CustomGr";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import { formatDate, getOrders } from "../../Api";
import Radial from "../../GraphicsComp/Radial";
import AnimationIcon from "@mui/icons-material/Animation";
import upgr from "./img/upgr.svg";
import AndroidIcon from "@mui/icons-material/Android";
import WindowIcon from "@mui/icons-material/Window";
import { useNavigate } from "react-router-dom";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { PieChart } from "@mui/x-charts/PieChart";
import PeopleIcon from "@mui/icons-material/People";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import Groups3Icon from "@mui/icons-material/Groups3";
import { routes } from "../../routes";
import { User } from "../../Contexts/User";
import AppleIcon from "@mui/icons-material/Apple";
import { pieArcLabelClasses } from "@mui/x-charts/PieChart";
import { BarChart } from "@mui/x-charts/BarChart";
import { LineChart } from "@mui/x-charts/LineChart";
const data = [
  { label: "0-18 Лет", value: 4952, color: "#0088FE" },
  { label: "18-30 Лет", value: 4100, color: "#00C49F" },
  { label: "30-45 Лет", value: 3200, color: "#FFBB28" },
  { label: "45-60 Лет", value: 2202, color: "#FF8042" },
];
const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
const pData = [2400, 1398, 9800, 3908, 4800, 3800, 4300];
const xLabels = [
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Декабрь",
  "Январь",
];
const sizing = {
  height: 200,
  width: 400,
};
const TOTAL = data.map((item) => item.value).reduce((a, b) => a + b, 0);

const getArcLabel = (params) => {
  const percent = params.value / TOTAL;
  return `${(percent * 100).toFixed(0)}%`;
};
export default function AdminMainPage() {
  const [context, setContext] = useContext(Context);
  const [user, setUser] = useContext(User);
  const [sub, setSub] = useState();
  const [orders, setOrders] = useState();
  const hell = useRef(null);
  const Navigate = useNavigate();
  const [summMagazine, setSummMagazine] = useState(0);
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = today.getFullYear();

  today = dd + "." + mm + "." + yyyy;
  useEffect(() => {
    if (hell && hell.current) {
      setTimeout(() => {
        hell.current.style = "opacity: 1";
      }, [3900]);
      setTimeout(() => {
        hell.current.style = "transform: scale(0)";
      }, [6500]);
    }
  }, [hell]);
  function getSubs() {
    let form = new FormData();
    form.append("ID", localStorage.id);
    fetch("https://comeback-team.ru/count.php", {
      method: "post",
      body: form,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setSub(data);
      });
  }
  useEffect(() => {
    getSubs();
    getOrders(localStorage.id).then((data) => {
      setOrders(data.reverse());
    });
  }, []);

  useEffect(() => {
    if (orders && orders.length > 0) {
      let sum = 0;
      for (let i = 0; i < orders.length; i++) {
        console.log(orders[i].StatusComp);
        if (orders[i].StatusComp == "Получен") {
          sum += Number(orders[i].summ);
        }
      }
      setSummMagazine(sum);
    }
  }, [orders]);
  return (
    <div className="container newMain">
      <div className="gridRobot">
        <div>
          {" "}
          <h1>Сводная статистика</h1>
          <div className="svodStat">
            <div>
              <div className="svodCont">
                <p>Прибыль </p>
                <article>
                  253 000₽<span>+55%</span>
                </article>
              </div>
              <div className="svodIcon">₽</div>
            </div>
            <div>
              <div className="svodCont">
                <p>Новые клиенты</p>
                <article>
                  24<span>+21%</span>
                </article>
              </div>
              <div className="svodIcon">
                <PeopleIcon></PeopleIcon>
              </div>
            </div>
            <div>
              <div className="svodCont">
                <p>Количество покупок</p>
                <article>
                  183<span>+12%</span>
                </article>
              </div>
              <div className="svodIcon">
                <LocalGroceryStoreIcon />
              </div>
            </div>
            <div>
              <div className="svodCont">
                <p>Количество пользователей</p>
                <article>
                  4582<span>+0.02%</span>
                </article>
              </div>
              <div className="svodIcon">
                <Groups3Icon />
              </div>
            </div>
          </div>
          <div className="lastSales">
            <p>Последние заказы</p>
            <div className="lastSalesBody">
              {orders &&
                orders.length > 0 &&
                orders.slice(0, 6).map((em, index) => {
                  return (
                    <div
                      className="orderItem"
                      key={"orderItem" + index}
                      onClick={() => {
                        Navigate(routes.orderDetails + `?key=${em.ID}`);
                      }}
                    >
                      <div className="ordIcon">
                        <CreditCardIcon />
                      </div>
                      <div className="ordSumm">
                        <article>Сумма заказа</article>
                        <span>
                          {/*  {em.StatusComp == "Отменен" ? "-" : "+"} */}
                          {em.summ}₽
                        </span>
                      </div>
                      <div className="ordSumm ordTime">
                        <article>Дата заказа</article>
                        <span>
                          {new Date(
                            JSON.parse(em.timeOrder)
                          ).toLocaleDateString()}
                        </span>
                      </div>
                      <div className="ordSumm ordTime">
                        <article>Телефон</article>
                        <span>
                          {em.telephone == "undefined" || !em.telephone
                            ? "-"
                            : em.telephone}
                        </span>
                      </div>
                      <div className="ordStatus">
                        <article>Статус</article>
                        <span>{em.StatusComp}</span>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="hello">
          <model-viewer
            camera-controls=""
            class="mods"
            touch-action="pan-y"
            autoplay=""
            disable-zoom
            scale="0.22 0.22 0.22"
            camera-orbit="90deg 90deg 2.9m"
            shadow-intensity="1"
            disable-pan
            src="./earth.glb"
            alt="Domovik"
            ar-status="not-presenting"
          >
            <button
              ref={hell}
              class="hotspot"
              slot="hotspot-hand"
              data-position="0.2 0.55 0.39"
              data-normal="0.2 0.55 0.39"
            >
              <div class="annotation">Добро пожаловать!</div>
            </button>
          </model-viewer>
          <div className="corcod">
            <div className="heartSold">
              <div className="valis">
                <p>100k</p>
                <p>50k</p>
                <p>0</p>
              </div>
              <div>
                <div className="line" style={{ height: "70%" }}></div>
              </div>
              <div>
                <div className="line" style={{ height: "20%" }}></div>
              </div>
              <div>
                <div className="line" style={{ height: "90%" }}></div>
              </div>
              <div>
                <div className="line" style={{ height: "30%" }}></div>
              </div>
            </div>{" "}
            <h3>Устройства пользователей</h3>
            <p className="lastf">Данные за последние 5 дней</p>
            <div className="flexEnd">
              <div className="statUs">
                <p>
                  <WindowIcon></WindowIcon>Windows
                </p>
                <h4>15.9K</h4>
                <div
                  className="lined"
                  style={{ gridTemplateColumns: "2fr 1fr" }}
                >
                  <div className="boldL"></div>
                  <div className="nullL"></div>
                </div>
              </div>
              <div className="statUs">
                <p>
                  <AnimationIcon></AnimationIcon>Linux
                </p>
                <h4>3.5K</h4>
                <div
                  className="lined"
                  style={{ gridTemplateColumns: "0.8fr 3fr" }}
                >
                  <div className="boldL"></div>
                  <div className="nullL"></div>
                </div>
              </div>
              <div className="statUs">
                <p>
                  <AppleIcon></AppleIcon>IOS/Mac OS
                </p>
                <h4>18k</h4>
                <div
                  className="lined"
                  style={{ gridTemplateColumns: "3fr 0.5fr" }}
                >
                  <div className="boldL"></div>
                  <div className="nullL"></div>
                </div>
              </div>
              <div className="statUs">
                <p>
                  <AndroidIcon></AndroidIcon>Android
                </p>
                <h4>36K</h4>
                <div className="lined">
                  <div className="boldL"></div>
                  <div className="nullL"></div>
                </div>
              </div>
            </div>
            <div className="newplan">Полный анализ</div>
            {/*   <div className="lining">
              <PieChart
                series={[
                  {
                    data: [
                      { id: 0, value: 38, label: "Windows" },
                      { id: 1, value: 10, label: "Max OS" },
                      { id: 2, value: 20, label: "Android" },
                    ],
                    innerRadius: 30,
                    outerRadius: 100,
                    paddingAngle: 5,
                    cornerRadius: 5,
                    startAngle: 0,
                    endAngle: 360,
                    cx: "100%",
                    cy: "100%",
                  },
                ]}
                {...pieParams}
              />
            </div> */}
          </div>
        </div>
      </div>
      <div className="downCont">
        <div className="od">
          <h3>Целевая аудитория</h3>
          <div className="seeMore">
            <SignalCellularAltIcon />
            Подробная статистика
          </div>
          <span>Показатели относительно гендера пользователей</span>
          <LineChart
            height={320}
            series={[
              { data: pData, label: "Женщины" },
              { data: uData, label: "Мужчины" },
            ]}
            xAxis={[{ scaleType: "band", data: xLabels }]}
            {...pieParams}
            margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
          />
        </div>
        <div className="od">
          <h3>Эффективность сотрудников</h3>{" "}
          <div className="seeMore">
            <SignalCellularAltIcon /> Подробная статистика
          </div>
          <span>Количество заказов, взятых менеджерами в этом месяце</span>
          <BarChart
            series={[
              { data: [35, 44, 24, 34] },
              { data: [51, 6, 49, 30] },
              { data: [15, 25, 30, 50] },
              { data: [60, 50, 15, 25] },
            ]}
            height={320}
            xAxis={[
              {
                data: ["Марина", "Евгений", "Кирилл", "Константин"],
                scaleType: "band",
              },
            ]}
            margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
          />
        </div>
      </div>
    </div>
  );
}
const pieParams = {
  slotProps: { legend: { hidden: true } },
};
