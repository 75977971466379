import { useEffect, useState } from "react";
import "./style.css";
import { Form, useNavigate } from "react-router-dom";
import { formatDate, getOrders, updateStatus } from "../../Api";
import messageI from "./message.svg";
import { Store } from "react-notifications-component";
import { routes } from "../../routes";
import SmsIcon from "@mui/icons-material/Sms";
import BlockIcon from "@mui/icons-material/Block";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FilterListIcon from "@mui/icons-material/FilterList";
import AppsIcon from "@mui/icons-material/Apps";
const typeOrders = [
  "Создан",
  "В сборке",
  "В доставке",
  "Ожидает получения",
  "Получен",
  "Готов к самовывозу",
  "Отменен",
];
export default function Orders() {
  const [orders, setOrders] = useState([]);
  const [currentType, setCurrentType] = useState(typeOrders[0]);
  const [openPop, isOpenPop] = useState(false);
  const [message, setMessage] = useState("");
  const Navigate = useNavigate();
  useEffect(() => {
    getOrders(localStorage.id).then((data) => {
      setOrders(data.reverse());
    });
  }, []);
  return (
    <div className="container ordP">
      <h2 className="descTitle">Страница заказов</h2>
      <p className="descText">
        Сюда поступают все заявки/заказы пользователей. Вы можете фиксировать
        заказ за своим аккаунтом, менять статусы заказов,
        <br /> в зависимости от степени его выполнения, смотреть детали заказа и
        решать вопросы пользователей по заказу через чат
      </p>
      <div className="typesOrder">
        <article className="hord"></article>
        {typeOrders.map((em, index) => {
          let lngth =
            orders &&
            orders.length > 0 &&
            orders.filter(function (value) {
              return em === value.StatusComp;
            }).length;
          return (
            <div
              className={`${em == currentType && "vkActive"}`}
              key={index}
              onClick={() => setCurrentType(em)}
              style={lngth == 0 ? { pointerEvents: "none" } : {}}
            >
              <p /* style={em == currentType ? { color: "#3E95FF" } : {}} */>
                {em}
              </p>
              <span style={em == currentType ? { background: "#0370f3" } : {}}>
                {lngth}
              </span>
            </div>
          );
        })}
      </div>
      <div className="filters">
        <h3>{currentType}</h3>

        <div className="rightFlt">
          <input type="text" placeholder="Поиск" />
          <AppsIcon></AppsIcon>
          <FilterListIcon></FilterListIcon>
        </div>
      </div>
      <div className="usersTable" key={currentType}>
        <div
          className="gridTable gridTitle"
          style={{
            gridTemplateColumns:
              "1.5vw 0.7fr 1fr 0.7fr 0.5fr  1.2fr 3vw 1.5vw 1.5vw",
          }}
        >
          <div></div>
          <div>Время заказа</div>
          <div>Менеджер</div>
          <div>Телефон</div>
          <div>Сумма</div>
          {document.body.clientWidth > 1700 && <div>Адрес</div>}
          <div>ID</div>
          <div></div>
          <div></div>
        </div>

        <div>
          {orders &&
            orders.length > 0 &&
            orders.map((em, index) => {
              if (em.StatusComp == currentType) {
                return (
                  <div
                    className="gridTable gridRow"
                    key={"order" + index}
                    style={{
                      gridTemplateColumns:
                        "1.5vw 0.7fr 1fr 0.7fr 0.5fr  1.2fr 3vw 1.5vw 1.5vw",
                    }}
                    onClick={() =>
                      Navigate(routes.orderDetails + `?key=${em.ID}`)
                    }
                  >
                    <div>
                      <CreditCardIcon />
                    </div>
                    <div className="userName">
                      {new Date(JSON.parse(em.timeOrder)).toLocaleDateString() +
                        " " +
                        new Date(
                          JSON.parse(em.timeOrder)
                        ).toLocaleTimeString() || "-"}
                    </div>
                    {currentType != "Создан" ? (
                      <div className="managerRow">
                        <img
                          src="https://static.life.ru/publications/2023/10/31/702721848451.3439.png"
                          alt=""
                        />
                        <span>Марина Красильникова</span>
                      </div>
                    ) : (
                      <div>
                        <div className="opOrder" style={{ opacity: "1" }}>
                          Взять заказ
                        </div>
                      </div>
                    )}
                    <p className="userName">
                      {em.telephone == "undefined" || !em.telephone
                        ? "-"
                        : em.telephone}
                    </p>
                    <p className="userName">
                      {Number(em.summ).toLocaleString()}₽
                    </p>
                    {document.body.clientWidth > 1700 && (
                      <p className="userName">
                        {" г.Санкт Петербург, ул. Ломоносова 24, парадная 3, кв. 23".slice(
                          0,
                          40
                        )}
                        ...
                      </p>
                    )}

                    <p className="userName">{em.ID}</p>
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        Navigate(routes.chat + `?key=${em.telephone}`);
                      }}
                    >
                      <SmsIcon></SmsIcon>
                    </div>
                    <div className="stRel">
                      <div className="changeSt">
                        <h3>Изменить статус</h3>
                        {typeOrders.map((elem) => {
                          if (elem != currentType)
                            return (
                              <p
                                onClick={(e) => {
                                  e.stopPropagation();
                                  updateStatus(em.ID, elem).then((res) => {
                                    getOrders(localStorage.id).then((data) => {
                                      Store.addNotification({
                                        title: `Заказ №${em.ID}`,
                                        message: `Статус заказа изменен на "${elem}"`,
                                        type: "success",
                                        insert: "top",
                                        container: "top-right",
                                        animationIn: [
                                          "animate__animated",
                                          "animate__bounceIn",
                                        ],
                                        animationOut: [
                                          "animate__animated",
                                          "animate__backOutUp",
                                        ],
                                        dismiss: {
                                          duration: 4000,
                                          onScreen: true,
                                        },
                                      });
                                      setOrders(data);
                                    });
                                  });
                                }}
                              >
                                • {elem}
                              </p>
                            );
                        })}
                      </div>
                      <KeyboardArrowDownIcon />
                    </div>
                  </div>
                );
              }
            })}
        </div>
      </div>
    </div>
  );
}
