import { useContext, useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import "./style.css";
import InputMask from "react-input-mask";
import { User } from "../../Contexts/User";
import {
  getInfoUser,
  updateAction,
  updateInfo,
  updateLogo,
  uploadImage,
} from "../../Api";
import { Store } from "react-notifications-component";
import { Navigate, useNavigate } from "react-router-dom";
import { routes } from "../../routes";
const fileTypes = ["JPG", "PNG", "GIF", "WEBP", "WEBM", "MP4"];
export default function Profile() {
  const [file, setFile] = useState(null);
  const [user, setUser] = useContext(User);
  const [videos, setVideos] = useState([]);
  const Navigate = useNavigate();
  const [id1, setid1] = useState(
    user && user.idActions ? JSON.parse(user.idActions)[0] : ""
  );
  const [id2, setid2] = useState(
    user && user.idActions ? JSON.parse(user.idActions)[1] : ""
  );
  const [id3, setid3] = useState(
    user && user.idActions ? JSON.parse(user.idActions)[2] : ""
  );
  const [id4, setid4] = useState(
    user && user.idActions ? JSON.parse(user.idActions)[3] : ""
  );
  useEffect(() => {
    if (user && user.Actions) {
      setVideos(JSON.parse(user.Actions));
    }
  }, [user]);
  function Notification(text) {
    Store.addNotification({
      title: `Профиль`,
      message: text,
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__bounceIn"],
      animationOut: ["animate__animated", "animate__backOutUp"],
      dismiss: {
        duration: 4000,
        onScreen: true,
      },
    });
  }
  const handleChange = (file) => {
    uploadImage(file).then((path) => {
      updateLogo(path, user.ID).then((response) => {
        getInfoUser(user.ID).then((data) => {
          setUser(data);
          Notification("Логотип успешно изменен");
        });
      });
    });
  };
  const handleChangeVideo1 = (file) => {
    uploadImage(file).then((path) => {
      let arr = JSON.parse(user.Actions);
      console.log(typeof arr);
      arr[0] = "https://comeback-team.ru/" + path;
      updateAction(JSON.stringify(arr), user.ID).then((response) => {
        getInfoUser(user.ID).then((data) => {
          setUser(data);
          Notification("Акция успешно изменена");
        });
      });
    });
  };
  const handleChangeVideo2 = (file) => {
    uploadImage(file).then((path) => {
      let arr = JSON.parse(user.Actions);
      console.log(typeof arr);
      arr[1] = "https://comeback-team.ru/" + path;
      updateAction(JSON.stringify(arr), user.ID).then((response) => {
        getInfoUser(user.ID).then((data) => {
          setUser(data);
          Notification("Акция успешно изменена");
        });
      });
    });
  };
  const handleChangeVideo3 = (file) => {
    uploadImage(file).then((path) => {
      let arr = JSON.parse(user.Actions);
      console.log(typeof arr);
      arr[2] = "https://comeback-team.ru/" + path;
      updateAction(JSON.stringify(arr), user.ID).then((response) => {
        getInfoUser(user.ID).then((data) => {
          setUser(data);
          Notification("Акция успешно изменена");
        });
      });
    });
  };
  const handleChangeVideo4 = (file) => {
    uploadImage(file).then((path) => {
      let arr = JSON.parse(user.Actions);
      console.log(typeof arr);
      arr[3] = "https://comeback-team.ru/" + path;
      updateAction(JSON.stringify(arr), user.ID).then((response) => {
        getInfoUser(user.ID).then((data) => {
          setUser(data);
          Notification("Акция успешно изменена");
        });
      });
    });
  };
  const handleChangeUser = (name, e) => {
    if (name == "RightPage") {
      console.log(e.target.value);
      setUser({ ...user, [name]: !user.RightPage });
    } else {
      setUser({ ...user, [name]: e.target.value });
    }
  };
  return (
    <div className="container profilePage">
      <div className="lfrProfile">
        <div className="redEdit">
          <h4>Телеграм:</h4>
          <input
            type="text"
            name="Tg"
            value={user && user.Tg}
            onChange={(e) => handleChangeUser(e.target.name, e)}
          />
        </div>
        <div className="redEdit">
          <h4>Whatsapp:</h4>
          <input
            type="text"
            name="Wt"
            value={user && user.Wt}
            onChange={(e) => handleChangeUser(e.target.name, e)}
          />
        </div>
        <div className="redEdit">
          <h4>Время работы:</h4>
          <input
            type="text"
            name="TimeWork"
            value={user && user.TimeWork}
            onChange={(e) => handleChangeUser(e.target.name, e)}
          />
        </div>
        <div className="redEdit">
          <h4>Телефон рабочий:</h4>
          <InputMask
            value={user && user.TelWork}
            mask="+7(999)999 99 99"
            maskChar=" "
            name="TelWork"
            onChange={(e) => handleChangeUser(e.target.name, e)}
          />
        </div>
        <div className="redEdit">
          <h4>Токен бота телеграм:</h4>
          <input
            type="text"
            name="tokenBot"
            value={user && user.tokenBot}
            onChange={(e) => handleChangeUser(e.target.name, e)}
          />
        </div>
        <div className="redEdit">
          <h4>Количество столбцов товаров:</h4>
          <input
            type="text"
            name="ColumnT"
            value={user && user.ColumnT}
            onChange={(e) => handleChangeUser(e.target.name, e)}
          />
        </div>
        {/*   <div className="redEdit">
          <h4>Правое меню:</h4>
          <div
            className="pokaz"
            onClick={(e) => {
              setUser({
                ...user,
                RightPage: user && user.RightPage == "1" ? "0" : "1",
              });
            }}
          >
            {user && user.RightPage == 1 ? "Показывается" : "Не показывается"}
          </div>
        </div> */}

        <div
          className="save"
          onClick={() => {
            updateInfo(user, id1, id2, id3, id4).then((data) => {
              getInfoUser(user.ID).then((data) => {
                setUser(data);
                Notification("Данные успешно обновлены");
              });
            });
          }}
        >
          Сохранить
        </div>
      </div>
      <div className="redEdit snow">
        <div className="chop">
          <h4>Логотип компании:</h4>
          <FileUploader
            label={"Загрузите или перетащите файл"}
            handleChange={handleChange}
            name="file"
            types={fileTypes}
          />
        </div>
        <div className="showLogo">
          {" "}
          <img src={user && user.Logo} alt="" />
        </div>
        <button className="csss" onClick={() => Navigate(routes.cssPage)}>
          CSS стили витрины
        </button>
      </div>
    </div>
  );
}
