import "./admin.css";
import adminPhone from "../imgs/adminPhone.png";
import AdminPass from "../imgs/adminPass.png";
import AdminProfileIcon from "../imgs/adminProfileIcon.png";
import comebackteamImg from "../../src/navbar/logo.svg";
import yourCompany from "../imgs/yourCompany.png";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
export default function AdminRegister() {
  const Navigate = useNavigate();
  const [form, setForm] = useState({
    tel: "+7",
    company: "",
    password: "",
    fio: "",
    email: "",
  });
  var errorIndex = 0;
  const [errorText, seterrorText] = useState("");
  const [errorPassText, seterrorPassText] = useState("");
  const handleChange = (event) => {
    switch (event.target.name) {
      case "email":
        setForm({
          ...form,
          email: event.target.value,
        });
        break;
      case "tel":
        mask(event);
        setForm({
          ...form,
          tel: event.target.value,
        });
        break;
      case "company":
        setForm({
          ...form,
          company: event.target.value,
        });
        break;
      case "password":
        setForm({
          ...form,
          password: event.target.value,
        });
        break;
      case "fio":
        setForm({
          ...form,
          fio: event.target.value,
        });
        break;
    }
    console.log(form);
  };
  const registration = (event) => {
    console.log("enterRegister");
    event.preventDefault();
    if (form.fio.length > 2 && form.tel.length > 11 && form.tel.length < 20) {
      seterrorText("");
      if (form.password.length >= 6) {
        seterrorText("");
        seterrorPassText("");
        const data = new FormData();
        data.append("FIO", form.fio);
        data.append("tel", form.tel);
        data.append("password", form.password);
        data.append("email", form.email);
        data.append("company", form.company);
        data.append("role", "admin");
        fetch("https://comeback-team.ru/registration.php", {
          method: "post",
          body: data,
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            console.log(data);
            if (data == "Ok") {
              Navigate("/login");
            } else {
              seterrorText(data);
            }
            //document.location.href = "htts://webby-digital.ru/thanks.html";
            //else alert(data);
          });
      } else {
        seterrorPassText("Длина пароля < 6 символов");
      }
    } else {
      seterrorText("Укажите ваше имя и телефон");
      if (form.password.length < 6) {
        seterrorPassText("Длина пароля < 6 символов");
      } else {
        seterrorPassText("");
      }
    }
  };
  function mask(event) {
    let keyCode;
    event.keyCode && (keyCode = event.keyCode);
    let pos = event.target.selectionStart;
    if (pos < 3) event.preventDefault();
    let matrix = "+7 (___) ___ ____",
      i = 0,
      def = matrix.replace(/\D/g, ""),
      val = event.target.value.replace(/\D/g, ""),
      new_value = matrix.replace(/[_\d]/g, function (a) {
        return i < val.length ? val.charAt(i++) || def.charAt(i) : a;
      });
    i = new_value.indexOf("_");
    if (i != -1) {
      i < 5 && (i = 3);
      new_value = new_value.slice(0, i);
    }
    let reg = matrix
      .substr(0, event.target.value.length)
      .replace(/_+/g, function (a) {
        return "\\d{1," + a.length + "}";
      })
      .replace(/[+()]/g, "\\$&");
    reg = new RegExp("^" + reg + "$");
    if (
      !reg.test(event.target.value) ||
      event.target.value.length < 5 ||
      (keyCode > 47 && keyCode < 58)
    )
      event.target.value = new_value;
    if (event.type == "blur" && event.target.value.length < 5)
      event.target.value = "";
  }

  return (
    <div className="admin_enter_container">
      <div className="comeback_team_box">
        <img className="comebackteamImg" src={comebackteamImg} />
      </div>
      <p className="admin_register_title">
        регистрация <br /> в личном кабинете
      </p>
      <div className="right_part_admin">
        <div className="admin_enter_inputs_white_background">
          <div className="admin_enter_inputs_box_register">
            <div className="admin_author_register_box">
              <Link
                to="/login"
                id="admin_choose_author"
                className="admin_chooser_disabled"
              >
                Авторизация
              </Link>
              <p id="admin_choose_register" className="admin_chooser_active">
                Регистрация
              </p>
            </div>
            <p className="admin_enter_inputs_title">Заполните поля для входа</p>
            <form onSubmit={registration}>
              <div className="admin_enter_phone_input_box">
                <div className="admin_enter_input_img_box">
                  <img className="AdminProfileIcon" src={AdminProfileIcon} />
                </div>
                <div className="password_is_short">{errorText}</div>
                <input
                  type="text"
                  className="admin_enter_inputs"
                  placeholder="Ваше имя"
                  name="fio"
                  value={form.fio}
                  onChange={handleChange}
                ></input>
              </div>
              <div className="admin_enter_phone_input_box">
                <div className="admin_enter_input_img_box">
                  <img className="Admin_company_icon" src={yourCompany} />
                </div>
                <input
                  type="text"
                  className="admin_enter_inputs"
                  placeholder="Название компании"
                  name="company"
                  value={form.company}
                  onChange={handleChange}
                ></input>
              </div>
              <div className="admin_enter_phone_input_box">
                <div className="admin_enter_input_img_box">
                  <img className="admin_enter_input_img" src={adminPhone} />
                </div>
                <input
                  type="tel"
                  className="admin_enter_inputs"
                  placeholder="Телефон"
                  name="tel"
                  value={form.tel}
                  onChange={handleChange}
                ></input>
              </div>
              <div className="admin_enter_phone_input_box">
                <div className="admin_enter_input_img_box">
                  <img className="admin_enter_input_img" src={adminPhone} />
                </div>
                <input
                  type="email"
                  className="admin_enter_inputs"
                  placeholder="Email"
                  name="email"
                  value={form.email}
                  onChange={handleChange}
                ></input>
              </div>
              <div className="admin_enter_phone_input_box">
                <div className="admin_enter_input_img_box">
                  <img className="AdminPass" src={AdminPass} />
                </div>
                <div className="password_is_short">{errorPassText}</div>
                <input
                  type="password"
                  className="admin_enter_inputs"
                  placeholder="Пароль"
                  name="password"
                  value={form.password}
                  onChange={handleChange}
                ></input>
              </div>
              <button onClick={registration} className="admin_enter_button">
                Зарегистрироваться
              </button>
              <div className="input_checkbox_box">
                <input
                  type="checkbox"
                  id="scales"
                  name="scales"
                  style={{ pointerEvents: "none" }}
                  defaultChecked
                />
                <p className="label_for_input" htmlFor="scales">
                  Даю согласие на{" "}
                  <a className="personal_agreement">
                    обработку персональных данных
                  </a>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
